<template>
  <div ref="canvas" id="canvas"></div>
</template>

<script>
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { MapControls } from 'three/examples/jsm/controls/DragControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

export default {
  name: 'SrcBeleptetoMap',

  data() {
    return {
      camera: null,
      controls: null,
      scene: null,
      raycaster: null,
      renderer: null,
      intersected: false,
    };
  },

  mounted() {
    this.init();

    // const loader = new SVGLoader();
    const loader = new GLTFLoader();
    // const dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath( 'three/examples/jsm/libs/draco/' );
    // loader.setDRACOLoader(dracoLoader);

    // Load a glTF resource
    loader.load(
      // resource URL
      // '/assets/star_trek_-_dsc_enterprise/scene.gltf',
      '/assets/scene.gltf',
      // called when the resource is loaded
      // (data) => {
      //   const paths = data.paths;
      //   const group = new THREE.Group();

      //   for (let i = 0; i < paths.length; i++) {
      //     const path = paths[i];

      //     const material = new THREE.MeshBasicMaterial({
      //       color: path.color,
      //       side: THREE.DoubleSide,
      //       depthWrite: false,
      //     });

      //     const shapes = SVGLoader.createShapes(path);

      //     for (let j = 0; j < shapes.length; j++) {
      //       const shape = shapes[j];
      //       const geometry = new THREE.ShapeGeometry(shape);
      //       const mesh = new THREE.Mesh(geometry, material);
      //       group.add(mesh);
      //     }
      //   }

      //   group.scale.set(100, 100, 100);

      //   scene.add(group);
      // },
      (gltf) => {
        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        gltf.scene.scale.set(5, 5, 5);
        this.scene.add(gltf.scene);
      },
      // called while loading is progressing
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // called when loading has errors
      function (error) {
        console.log('An error happened', error);
      }
    );

    this.render(); // remove when using next line for animation loop (requestAnimationFrame)
    this.animate();
  },

  methods: {
    init() {
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff);
      this.scene.fog = new THREE.FogExp2(0xffffff, 0.0012);

      const gridHelper = new THREE.GridHelper(400, 10);
      this.scene.add(gridHelper);

      this.raycaster = new THREE.Raycaster();

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvas.appendChild(this.renderer.domElement);

      // this.camera = new THREE.OrthographicCamera( -100, 100, 100, -100, 1, 1000 );
      this.camera = new THREE.PerspectiveCamera(
        30,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
      this.camera.position.set(400, 200, 0);

      // controls

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.listenToKeyEvents(window); // optional

      //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.05;
      // this.controls.

      this.controls.screenSpacePanning = false;

      this.controls.minDistance = 20;
      this.controls.maxDistance = 1600;

      this.controls.keyPanSpeed = 20;

      // controls.autoRotate = true;
      // controls.autoRotateSpeed = 0.1;

      this.controls.maxPolarAngle = Math.PI / 2;

      // world

      const geometry = new THREE.CylinderGeometry(0, 10, 30, 4, 1);
      const material = new THREE.MeshPhongMaterial({ color: 0x888888, flatShading: true });

      // for (let i = 0; i < 100; i++) {
      //   const mesh = new THREE.Mesh(geometry, material);
      //   mesh.position.x = Math.random() * 400 - 200;
      //   mesh.position.y = Math.random() * 10;
      //   mesh.position.z = Math.random() * 400 - 200;
      //   mesh.scale.set(Math.random(), Math.random(), Math.random());
      //   mesh.updateMatrix();
      //   mesh.matrixAutoUpdate = false;
      //   this.scene.add(mesh);
      // }

      const dirLight1 = new THREE.DirectionalLight(0xffffff);
      dirLight1.position.set(10, 1, 1);
      dirLight1.intensity = 1;
      this.scene.add(dirLight1);

      const dirLight2 = new THREE.DirectionalLight(0xfffaee);
      dirLight2.position.set(-1, 1, -1);
      dirLight2.intensity = 1;
      this.scene.add(dirLight2);

      const dirLight3 = new THREE.DirectionalLight(0xfffaee);
      dirLight3.position.set(10, 10, 10);
      dirLight3.intensity = 1;
      this.scene.add(dirLight3);

      // const ambientLight = new THREE.AmbientLight(0x888888);
      // ambientLight.intensity = 40;
      // this.scene.add(ambientLight);

      document.addEventListener('mousemove', this.onPointerMove);
      window.addEventListener('resize', this.onWindowResize);
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
      this.render();
    },

    render() {
      const intersects = this.raycaster.intersectObjects(this.scene.children, false);

      // if (intersects.length > 0) {
      //   if (this.intersected != intersects[0].object) {
      //     if (this.intersected)
      //       // this.intersected.material.emissive.setHex(this.intersected.currentHex);

      //     this.intersected = intersects[0].object;
      //     // this.intersected.currentHex = this.intersected.material.emissive.getHex();
      //     // this.intersected.material.emissive.setHex(0x000044);
      //   }
      // } else {
      //   if (this.intersected)
      //     // this.intersected.material.emissive.setHex(this.intersected.currentHex);

      //   this.intersected = null;
      // }

      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style scoped>
#canvas {
  width: 100vw;
  height: 100vh;
}
</style>
